"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMeResponse = void 0;
const typebox_1 = require("@sinclair/typebox");
const errors_1 = require("../../errors");
const http_status_codes_1 = require("../../http-status-codes");
const rest_errors_1 = require("../rest-errors");
/**
 * Describes valid response HTTP status codes paired with response body for REST
 * API endpoint to interrogate current credentials.
 */
exports.GetMeResponse = typebox_1.Type.Union([
    typebox_1.Type.Object({
        statusCode: typebox_1.Type.Literal(http_status_codes_1.HttpStatusCode.Ok),
        body: typebox_1.Type.Object({
            accountId: typebox_1.Type.String({
                description: 'id of the account associated with the credentials',
                format: 'uuid',
                title: 'Account Id',
            }),
            email: typebox_1.Type.String({
                description: 'The email address associated with the account',
                format: 'email',
                title: 'Account Email',
            }),
        }),
    }, { description: 'Specifies account of the associated token' }),
    typebox_1.Type.Object({
        statusCode: typebox_1.Type.Literal(http_status_codes_1.HttpStatusCode.Locked),
        body: (0, errors_1.makeErrorResponseSchema)(typebox_1.Type.Literal('NotApiToken', {
            default: 'NotApiToken',
            description: 'The provided session id did not map to an API token',
        })),
    }, { description: 'The token was not a valid API Token' }),
    typebox_1.Type.Object({
        statusCode: typebox_1.Type.Literal(http_status_codes_1.HttpStatusCode.NotFound),
        body: (0, errors_1.makeErrorResponseSchema)(typebox_1.Type.Literal('CredentialNotExist', {
            default: 'CredentialNotExist',
            description: 'The associate credential information was not found',
        })),
    }, { description: 'No account associated with the API Token' }),
    ...rest_errors_1.RestErrorResponse.anyOf,
]);
